body {
  margin: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #f4f7fa;
  color: #333;
}

.App {
  text-align: center;
  padding: 20px;
  min-height: 100vh;
}

.App-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 10px 20px;
  color: #333;
  border-bottom: 2px solid #ddd;
}

h1 {
  margin: 0;
  font-size: 1.8rem;
}

.button-container {
  display: flex;
  gap: 10px;
}

.btn-login,
.btn-stop-log,
.btn-toggle {
  padding: 5px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  color: #fff;
}

.btn-login {
  background-color: #007bff;
}

.btn-stop-log {
  background-color: #dc3545;
}

.btn-toggle {
  background-color: #6c757d;
}

.content-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}

.log-box,
.connection-box {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.connection-box h2 {
  margin-top: 0;
  color: #333;
}

.dark-mode .connection-box h2 {
  color: #e0e0e0;
}

.table-container {
  margin-top: 10px;
  overflow-x: auto;
}

.table-container table {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
}

th {
  background-color: #1e73be;
  color: white;
  padding: 10px;
  text-align: center;
}

td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: center;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

tr:hover {
  background-color: #e9e9e9;
}

.dark-mode {
  background-color: #121212;
  color: #e0e0e0;
}

.dark-mode body,
.dark-mode .App,
.dark-mode .content-container {
  background-color: #121212;
  color: #e0e0e0;
}

.dark-mode .App-header {
  background-color: #1f1f1f;
  color: #e0e0e0;
  border-bottom: 2px solid #333;
}

.dark-mode .log-box,
.dark-mode .connection-box {
  background-color: #1f1f1f;
  border: 1px solid #333;
  color: #e0e0e0;
}

.dark-mode table {
  background-color: #1f1f1f;
  box-shadow: none;
}

.dark-mode th {
  background-color: #333;
  color: #e0e0e0;
}

.dark-mode td {
  border: 1px solid #444;
}

.dark-mode tr:nth-child(even) {
  background-color: #2c2c2c;
}

.dark-mode tr:hover {
  background-color: #3a3a3a;
}

.filter-container {
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 10px;
}

.filter-button {
  padding: 8px 12px;
  background-color: #6c757d;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
}

.filter-button:hover {
  background-color: #5a6268;
}

.filter-dropdown {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  z-index: 10;
  color: #333;
}

.filter-button:hover .filter-dropdown {
  display: block;
}

.filter-dropdown input {
  margin-right: 5px;
}

.dark-mode .filter-button {
  background-color: #333;
  color: #e0e0e0;
}

.dark-mode .filter-button:hover {
  background-color: #444;
}

.dark-mode .filter-dropdown {
  background-color: #1f1f1f;
  border: 1px solid #444;
  color: #e0e0e0;
}

.dark-mode .filter-dropdown input {
  background-color: #2c2c2c;
  color: #e0e0e0;
}
